import {
  ComponentFactoryResolver,
  ComponentRef,
  ContentChild,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subscription, tap } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ControlContainer } from '@angular/forms';
import { ControlErrorComponent } from '../control-error/control-error.component';

const ERROR_EXIST_CLASS = 'show-errors-list';
const MIGRATION_ERROR = 'Your email address is associated with a different system. Please login at courses.corporatefinanceinstitute.com.';
const MIGRATION_ERROR_WITH_LINK =
  'Your email address is associated with a different system. Please login at <a href="https://courses.corporatefinanceinstitute.com">courses.corporatefinanceinstitute.com</a>.';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form',
})
export class FormSubmitDirective implements OnInit, OnDestroy {
  ref: ComponentRef<ControlErrorComponent>[] = [];
  container: ViewContainerRef;
  subs = new Subscription();
  @ContentChild('errorContainer', { read: ViewContainerRef }) containerRef!: ViewContainerRef;
  @ContentChild('errorContainer') containerEl!: any;

  submit$ = fromEvent(this.element, 'submit').pipe(
    tap(() => {
      if (!this.element.classList.contains('form-submitted')) {
        this.element.classList.add('form-submitted');
      }
    }),
    shareReplay(1),
  );

  constructor(
    private vcr: ViewContainerRef,
    private host: ElementRef<HTMLFormElement>,
    private formControl: ControlContainer,
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2,
  ) {
    this.container = vcr;
  }

  get element(): any {
    return this.host.nativeElement;
  }

  get control(): any {
    return this.formControl;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      this.control?.statusChanges.subscribe(() => {
        const formServerErrors = this.control?.errors?.serverError || null;
        if (formServerErrors) {
          this.renderError(formServerErrors);
        } else {
          this.renderError(null);
        }
      }),
    );
  }

  private renderError(errorList: string[] | null): void {
    const parent = this.containerEl?.elementRef.nativeElement.parentElement;
    if (parent) {
      this.renderer.removeClass(parent, ERROR_EXIST_CLASS);
      this.ref.forEach(ref => ref.destroy());
      errorList?.forEach((error, index) => {
        const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
        this.ref[index] = this.containerRef.createComponent(factory, 0);
        if (error === MIGRATION_ERROR) {
          this.ref[index].instance.text = MIGRATION_ERROR_WITH_LINK;
        } else {
          this.ref[index].instance.text = error;
        }
        this.renderer.addClass(parent, ERROR_EXIST_CLASS);
      });
    }
  }
}
