import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ControlErrorsDirective } from './directives/control-error.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { ControlErrorContainerDirective } from './directives/control-error-container.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlErrorComponent } from './control-error/control-error.component';
import { SharedUiUiComponentsModule } from '@lms/shared-ui/ui-components';
import { SharedPipesModule } from '@lms/shared/pipes';

@NgModule({
  imports: [ReactiveFormsModule, SharedUiUiComponentsModule, FormsModule, SharedPipesModule],
  declarations: [ControlErrorsDirective, ControlErrorComponent, ControlErrorContainerDirective, FormSubmitDirective],
  exports: [ControlErrorsDirective, ControlErrorComponent, ControlErrorContainerDirective, FormSubmitDirective],
})
export class SharedFormValidationModule {
  constructor(@Optional() @SkipSelf() parentModule: SharedFormValidationModule) {
    // if (parentModule) {
    //   throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    // }
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedFormValidationModule,
    };
  }
}
