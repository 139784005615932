import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CurrentUserStorage, TwoFaCompletedStorageService } from '@lms/shared/storages';
import { Router } from '@angular/router';
import { NotifierService } from '@lms/shared/services/app-services';
import { ApiErrorStatusEnum, ApiService, Violation } from '@lms/shared/services/api-services';
import { catchError } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { Routing } from '@lms/shared/constants';

@Injectable()
export class FormValidationInterceptor implements HttpInterceptor {
  constructor(
    private currentUserStorage: CurrentUserStorage,
    private twoFaCompletedStorage: TwoFaCompletedStorageService,
    private router: Router,
    private notifierService: NotifierService,
    private apiService: ApiService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('/version')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(catchError((err: unknown) => this.processError(err)));
  }

  private processError(err: any): Observable<never> {
    const form = this.apiService.form;
    if (form) {
      this.renderValidationErrors(err, form);
      this.apiService.form = null;
    }

    const errorCode = err?.error?.status?.code;
    let errorMessages = ApiErrorStatusEnum.getMessages(errorCode, err?.error?.status?.desc);
    if (errorCode === ApiErrorStatusEnum.AUTH_SESSION_EXPIRED_EXCEPTION || errorCode === ApiErrorStatusEnum.AUTH_NOT_AUTHORIZED_EXCEPTION) {
      this.currentUserStorage.setCurrentUser(null);
      this.router.navigate(['/auth']);
    } else if (errorCode === ApiErrorStatusEnum.AUTH_TWO_FACTOR_CODE_NEEDED_EXCEPTION) {
      this.twoFaCompletedStorage.setState(false);
      this.router.navigate(['/auth/2fa']);
    } else if (err?.status === 413) {
      errorMessages = ['The file is too large.'];
    } else if (errorCode === ApiErrorStatusEnum.AUTH_ENROLLMENT_EXPIRED_EXCEPTION) {
      return throwError(err);
    } else if (errorCode === ApiErrorStatusEnum.AUTH_COMPANY_ACCESS_DENIED_EXCEPTION) {
      this.currentUserStorage.setCurrentUser(null);
      window.location.href = Routing.DASHBOARD;
    } else if (
      errorCode === ApiErrorStatusEnum.AUTH_RESET_PASSWORD_NOT_NEEDED_EXCEPTION ||
      errorCode === ApiErrorStatusEnum.AUTH_RESET_PASSWORD_TOKEN_HAS_EXPIRED_EXCEPTION
    ) {
      return throwError(err);
    }
    for (const index in errorMessages) {
      this.notifierService.error(errorMessages[index]);
    }
    return throwError(err);
  }

  public renderValidationErrors(err: Error, form: AbstractControl): void {
    if (!(err instanceof HttpErrorResponse)) {
      return;
    }
    form.setErrors({});
    if (err.status === 400) {
      const validationErrors = err.error?.status?.desc?.reduce(
        (acc: { [key: string]: Array<string> }, { path: pathname, message }: Violation | { path: string; message: string }) => {
          pathname = pathname ? pathname : '_default';
          if (!acc[pathname]) {
            acc[pathname] = [];
          }
          acc[pathname].push(message);

          return acc;
        },
        {},
      );
      Object.keys(validationErrors).forEach(prop => {
        const formControl = form.get(prop);
        if (formControl) {
          // activate the error message
          formControl.setErrors({
            serverError: validationErrors[prop], // TODO validationErrors[prop] can be a collection!
          });
        } else {
          form.setErrors({
            ...form.errors,
            serverError: validationErrors[prop],
          });
        }
      });
    }
  }
}
