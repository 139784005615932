import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: (): string => {
    return `This field is required`;
  },
  minlength: ({ requiredLength, actualLength }: Record<string, any>): string => `Expect ${requiredLength} but got ${actualLength}`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: (): { minlength: ({ requiredLength, actualLength }: Record<string, any>) => string; required: () => string } => defaultErrors,
});
